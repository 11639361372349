import DoneBtn from "../../FormComponents/DoneBtn";

const PractitionerFinalSection = () => {

    const finaliseSubmission = () => {}

    return (
        <>
            <h2 className={"text-primary fw-bold"}>
                Thank you for submitting your application!
            </h2>
            <p className={"fs-5 my-3 fw-medium"}>
               What is the highest grade passed?
            </p>

            <p className={"fs-5 my-3 fw-medium"}>
               What is the highest grade passed?
            </p>

            <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                <DoneBtn onClick={() => finaliseSubmission()}/>
            </div>

        </>
    );

};

export default PractitionerFinalSection;