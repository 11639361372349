import {FranchisorLead, InvestorLead, PractitionerLead} from "../types/types";

export const PractitionerLeadState: PractitionerLead = {
    id: "",
    firstName: "",
    surname: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
}

export const InvestorLeadState: InvestorLead = {
    leadID: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    company: "",
    emailAddress: "",
    phone: "",
    continent: "",
    country: "",
    industry: "",
    description: ""
}

export const FranchisorLeadState: FranchisorLead = {
    firstName: "",
    lastName: "",
    organisation: "",
    registrationNumber: "",
    province: "",
    districtMunicipality: "",
    localMunicipality: "",
    officeAddress: "",
    sectorFocus: "",
    geographicArea: [],
    mobileNumber: "",
    emailAddress: ""
}
