const NavBar = () => {

    return (
        <>
            <nav className="navbar d-flex flex-row justify-content-between">
                <div className="navbar-brand">
                    <div className="logo" ></div>
                </div>
                <div className="nav-options float-right text-primary pe-4 d-none">
                    <i className="fa fa-search me-4" ></i>
                    <i className="fa fa-bars" ></i>
                </div>
            </nav>
        </>
    );

}

export default NavBar;