import {Province} from "../types/types";

export const BaseURL: string = process.env.REACT_APP_WEBAPI_URL!;

export const Provinces: Province[] = [
    {code: "EC", name: "Eastern Cape"},
    {code: "FS", name: "Free State"},
    {code: "GP", name: "Gauteng"},
    {code: "KZN", name: "KwaZulu-Natal"},
    {code: "LP", name: "Limpopo"},
    {code: "MP", name: "Mpumalanga"},
    {code: "NC", name: "Northern Cape"},
    {code: "NW", name: "North West"},
    {code: "WC", name: "Western Cape"},
];