import {FormInputProps} from "./FormSearchInput";


const FormInput = (props: FormInputProps) => {
    return (
        <div className="form-group">
            <input
                className="form-control border-0 text-input"
                aria-label={props.placeholder}
                type={props.inputType}
                onChange={(e) => props.getValue(e.target.value)}
                placeholder={props.defaultValue||props.placeholder} required={!!props.isRequired}
                disabled = {!!props.disabled}
            />
        </div>
    );
}

export default FormInput;