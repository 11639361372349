import { configureStore } from "@reduxjs/toolkit";
import { dbeSlice } from "./dbe/dbeSlice";

export const store = configureStore({
  reducer: {
    dbe: dbeSlice.reducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;