import BaseBtn from "./BaseBtn";

export interface FormButtonProps {
    onClick: () => void;
    type?: string;
}

const NextBtn = (props: FormButtonProps) => {
        return (
           <BaseBtn onClick={()=>props.onClick()} className={"btn-next"} label={""}/>
        );
    }
;

export default NextBtn;