import NavBar from "./components/NavBar";
import {Route, Routes} from "react-router-dom";
import PractitionerFormView from "./views/practitionerFormView";
import ConsentFormView from "./views/consentFormView";
import FranchisorFormView from "./views/franchisorFormView";

const App = () => {
    return (
        <>
            <NavBar/>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-3">
                        <Routes>
                            <Route path="/form/practitioner" element={<PractitionerFormView/>}/>
                            <Route path="/form/franchisor" element={<FranchisorFormView/>}/>
                            <Route path="/consent/:id" element={<ConsentFormView/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}


export default App;
