import {ReactNode } from "react";

export interface MessageSectionProps {
    title:string,
    body: ReactNode
    button: ReactNode
}

const MessageSection = (props: MessageSectionProps) => {
    return (
        <>
            <h2 className={"text-primary"}>
                {props.title} 
            </h2>
            {props.body}
            <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                {props.button}
            </div>
       </>
    );
};

export default MessageSection;