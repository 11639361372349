import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Dbe } from '../types';

type initialStateType = {
  dbe: Dbe;
};
const initialState: initialStateType = {
  dbe: {
    personNumber: '0795208970',
    consentGiven: false,
  }
};

export const dbeSlice = createSlice({
  name: "dbe",
  initialState,
  reducers: {
    dbeConsentAccepted: (state, action: PayloadAction<Dbe>) => {
      state.dbe = action.payload;
    },
    dbeConsentDenied: (state, action: PayloadAction<Dbe>) => {
      state.dbe = action.payload;
    },
    setDbeConsent: (state, action: PayloadAction<Dbe>) => {
      state.dbe = action.payload;
    }
  },
});

export const { dbeConsentAccepted, dbeConsentDenied, setDbeConsent } = dbeSlice.actions;

export const selectDbe = (state: RootState) => state.dbe.dbe;
export default dbeSlice.reducer;