export interface FormSelectProps {
    name: string;
    onSelect: (value: string) => void;
    options: {}[];
};

const FormSelect = (props:FormSelectProps)=>{
    return (
        <>
            <div className="relative w-100">
                <select className="border-0 mb-2 p-2 bg-light" onChange={(e)=>props.onSelect(e.target.value)}>
                    <option value="" disabled selected className="text-primary">{props.name}</option>
                    {
                        props.options.map((option: any, index: number) => (
                            <option key={index} value={option.value}>{option.name}</option>
                        ))
                    }
                </select>
            </div>
        </>
    );
}

export default FormSelect;