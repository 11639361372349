import { FormButtonProps } from "./NextBtn";

const SubmitBtn = (props: FormButtonProps) => {
    return (
        <button className={"btn btn-submit text-start"} onClick={() => props.onClick()}
                type={props.type as "submit" | "reset" | "button" | undefined}
        >
            <span className="fw-bold"></span>
        </button>
    );
}
;

export default SubmitBtn;