import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/App.css';
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import reportWebVitals from './config/reportWebVitals';
import { store } from "./state/store";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
