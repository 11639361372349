import {useState} from "react";
import FormInput from "../../FormComponents/FormInput";
import NextBtn from "../../FormComponents/NextBtn";
import FormSelect from "../../FormComponents/FormSelect";
import { validateEmail, validateName, validatePhoneNumber } from "../../../store/actions/validaters";
import { Provinces } from "../../../store/states/initialStates";

interface PersonalInformationSectionData {
    onComplete: (e: Object) => void
}

const PersonalInformationSection = (props: PersonalInformationSectionData) => {

    const [identity, setIdentity] = useState<string>("");
    const[identytyType, setIdentityType] = useState<string>("");

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);
    const [, setUSeTermsAccepted] = useState<boolean>(false);

    const [province, setProvince] = useState<string>("");
    const [suburb, setSuburb] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [districtMunicipality, setDistrictMunicipality] = useState<string>("");
    const [localMunicipality, setLocalMunicipality] = useState<string>("");
    const[communicationMethod, setCommunicationMethod] = useState<string>("sms");

    const validateData = () => {
        if (!validateName(firstName)) {
            alert("First Name is not valid");
            return false;
        }
        if (!validateName(lastName)) {
            alert("Surname is not valid");
            return false;
        }
        if (!validateEmail(email)) {
            alert("Email is not valid");
            return false;
        }
        if (!validatePhoneNumber(phoneNumber)) {
            alert("Phone number is not valid");
            return false;
        }
        return true;
    }

    const saveData = () => {
        if (validateData()) {
            const personalInformation: Object = {
                identity: identity,
                firstName: firstName,
                LastName: lastName,
                email: email,
                phoneNumber: phoneNumber
            };
            props.onComplete(personalInformation);
        }
    };

    return (
        <>
            <h2 className={"text-primary"}>
                Apply now
            </h2>
            <p className={"fs-6 my-3"}>
                Fields marked * are required
            </p>

            <h4 className="fs-5 text-primary">
                Personal Information
            </h4>

            <FormInput inputType={"text"} placeholder={"First name*"} isRequired={true} getValue={setFirstName}/>

            <FormInput inputType={"text"} placeholder={"Last name*"} isRequired={true} getValue={setLastName}/>

            <FormInput inputType={"email"} placeholder={"Email Address"} isRequired={true} getValue={setEmail}/>

            <FormInput inputType={"tel"} placeholder={"Phone Number*"} isRequired={true} getValue={setPhoneNumber}/>

            <FormInput inputType={"text"} placeholder={"Identity number*"} isRequired={true} getValue={setIdentity}/>
            
            <h4 className="fs-5 text-primary my-2">
                Address
            </h4>

            <FormSelect onSelect={()=>{}} options={Provinces} name={"Province*"}   />

            <FormSelect onSelect={()=>{}} options={Provinces} name={"Suburb**"}   />

            <FormSelect onSelect={()=>{}} options={Provinces} name={"Postal Code*"}   />

            <FormSelect onSelect={()=>{}} options={Provinces} name={"Distric Municipality*"}   />

            <FormSelect onSelect={()=>{}} options={Provinces} name={"Local Municipality*"}   />

            <hr className="my-4" />
            <p>
                What is your preferred method of comunication?
            </p>
            
            <div className="row mb-4">
                <div className="col-3">
                    <div className="flex items-center d-flex flex-row justify-content-start align-items-center">
                        <input type="radio" id="sms" checked={communicationMethod==="sms"} onChange={()=>setCommunicationMethod("sms")} className="me-2 radio"/>
                        <label htmlFor="sms">SMS</label>
                    </div>
                </div>
                <div className="col-3">
                    <div className="flex items-center d-flex flex-row justify-content-start align-items-center">
                        <input type="radio" id="email" checked={communicationMethod==="email"} onChange={()=>setCommunicationMethod("email")} className="me-2 radio"/>
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
            </div>

            <div className="container d-flex flex-column align-items-center justify-content-center">
                <NextBtn onClick={() => saveData()}/>
            </div>
        </>
    );
}

export default PersonalInformationSection;