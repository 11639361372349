import {useState} from "react";
import NextBtn from "../../FormComponents/NextBtn";
import FormSelect from "../../FormComponents/FormSelect";
import { Provinces } from "../../../store/states/initialStates";

interface EligibilityInformationProps {
    onComplete: (data: Object) => void;
}

const EligibilityInformationSection = (props: EligibilityInformationProps) => {

    const [convictionStatus, setConvictionStatus] = useState<boolean>();

    const handleConvictionStatus = (status: boolean) => {
        setConvictionStatus(status);
    }

    return (
        <>
            <h2 className={"text-primary fw-bold"}>
                Eligibility
            </h2>
            <p className={"fs-5 my-3 fw-medium"}>
               What is the highest grade passed?
            </p>
            <p className="text-primary">
                Have you ever been convicted of a crime?
            </p>
            <div className="form-group">
                <FormSelect onSelect={(e)=>{}} options={Provinces} name={"Education Level*"}   />
            </div>
            <div className="row">
                <div className="col-4 flex items-center d-flex flex-row justify-content-start align-items-center">
                    <input type="radio" id="Yes" checked={convictionStatus === true || undefined} onChange={()=>handleConvictionStatus(true)} className="me-2 radio"/>
                    <label htmlFor="Yes">Yes</label>
                </div>
                <div className=" col-4 flex items-center d-flex flex-row justify-content-start align-items-center">
                    <input type="radio" id="No" checked={convictionStatus === false || undefined} onChange={()=>handleConvictionStatus(false)} className="me-2 radio"/>
                    <label htmlFor="sms">No</label>
                </div>
            </div>

            <p className="text-primary">
                What was the highest grade that you've passed at school?
            </p>


            <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                <NextBtn onClick={() => props.onComplete({})}/>
            </div>
        </>
    );

}

export default EligibilityInformationSection;