export interface BaseBtnProps {
    onClick: () => void;
    type?: string;
    className:string,
    label?:string
}

const BaseBtn = (props: BaseBtnProps) => {
        return (
            <button className={"btn text-start "+props.className} onClick={() => props.onClick()}
                    type={props.type as "submit" | "reset" | "button" | undefined}
            >
                    <span className="fw-bold fs-5">
                        {props.label}
                    </span>
            </button>
        );
    }
;

export default BaseBtn;