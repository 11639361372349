import DoneBtn from "../../FormComponents/DoneBtn";

const FranchisorFinalScreen = () => {

    return (
       <>
            <h2 className={"text-primary"}>
                Thank you for submitting your application! 
            </h2>
            <p className={"fs-6 my-3"}>
                Your information has been successfully received and forwarded to the appropriate team member. 
                They will be reaching out to you soon, either by phone or email, to discuss next steps.
            </p>
            <p className={"fs-6 my-3"}>
                If you'd like to check the status of your application, you can do so by contacting the Smartstart call center at 0800 014 817. Please make sure to provide your application status reference (Lead ID) when speaking with a call center agent.
            </p>
            <p className={"fs-6 my-3"}>
                We appreciate your interest and look forward to the opportunity to further assist you.
            </p>
            <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                <DoneBtn onClick={() => {}}/>
            </div>
       </>
    )

};

export default FranchisorFinalScreen;