import { useState } from "react";
import FormInput from "../components/FormComponents/FormInput";
import { FranchisorLead } from "../store/types/types";
import { FranchisorLeadState } from "../store/states/states";
import FormSelect from "../components/FormComponents/FormSelect";
import { Provinces } from "../store/states/initialStates";
import { validateEmail } from "../store/actions/validaters";
import DoneBtn from "../components/FormComponents/DoneBtn";
import SubmitBtn from "../components/FormComponents/SubmitBtn";
import FranchisorFinalScreen from "../components/sections/franchisorForm/franchisorFinalSection";

interface ValidationError {
    fieldName: string;
    message: {
        message:string,
        status:boolean
        type:string
    }
}

const FranchisorFormView = () => {
    const [franchisor, setFranchisor] = useState<FranchisorLead>(FranchisorLeadState);
    const [submittionComplete, setSubmittionComplete] = useState<boolean>(false);

    const updateFranchisor = (field:string,e: string) => {
        setFranchisor({ ...franchisor, [field]: e });
    };
    
    const doSubmitForm=()=>{
        setSubmittionComplete(true);
    }

    return (
        submittionComplete?<FranchisorFinalScreen/>:
        <>
            <h2 className={"text-primary"}>
                Apply now
            </h2>
            <p className={"fs-6 my-3"}>
                Fields marked * are required
            </p>

            <h4 className="fs-5 text-primary">
                Personal information
            </h4>

            <FormInput inputType={"text"} placeholder={"First name*"} isRequired={true} getValue={(e)=>updateFranchisor('firstName',e)}/>
            <FormInput inputType={"text"} placeholder={"Last name*"} isRequired={true} getValue={(e)=>updateFranchisor('lastName',e)}/>
            <FormInput inputType={"text"} placeholder={"Mobile number*"} isRequired={true} getValue={(e)=>updateFranchisor('mobileNumber',e)}/>
            <FormInput inputType={"email"} placeholder={"Email address*"} isRequired={true} getValue={(e)=>updateFranchisor('emailAddress',e)}/>

            <h4 className="fs-5 text-primary">
                Organisation information
            </h4>

            <FormInput inputType={"text"} placeholder={"Organisation*"} isRequired={true} getValue={(e)=>updateFranchisor('organisation',e)}/>
            <FormInput inputType={"text"} placeholder={"Registration number*"} isRequired={true} getValue={(e)=>updateFranchisor('registrationNumber',e)}/>
            <FormSelect name={"Sector focus*"} onSelect={(e) => updateFranchisor('sectorFocus', e)} options={[]}/>

            <h4 className="fs-5 text-primary">
                Organisation location
            </h4>
            <FormInput inputType={"text"} placeholder={"Office address*"} isRequired={true} getValue={(e)=>updateFranchisor('officeAddress',e)}/>
            <FormSelect name={"Province*"} onSelect={(e) => updateFranchisor('province', e)} options={Provinces} />
            <FormSelect name={"District municipality*"} onSelect={(e) => updateFranchisor('districtMunicipality', e)} options={Provinces} />
            <FormSelect name={"Local municipality*"} onSelect={(e) => updateFranchisor('officeAddress', e)} options={Provinces} />
            <FormSelect name={"Geographic area*"} onSelect={(e) => updateFranchisor('geographicArea', e)} options={Provinces} />

            <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                <SubmitBtn onClick={() => doSubmitForm()}/>
            </div>
        </>
    );
};

export default FranchisorFormView;