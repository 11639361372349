export const validateName = (name: string) => {
    const namePattern = /^[a-zA-Z]+$/;
    return namePattern.test(name);
};

export const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
};

export const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
};

export const validatePassportNumber = (passportNumber: string) => {
    const passportNumberPattern = /^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/;
    return passportNumberPattern.test(passportNumber);
};

