// React JSX Form for Practitioner
import React, {useState} from 'react';
import {PractitionerLead} from '../store/types/types';
import {useDispatch} from "react-redux";
import EligibilityInformationSection from '../components/sections/practitionerForm/EligibilityInformationSection';
import PersonalInformationSection from '../components/sections/practitionerForm/PersonalInformationSection';
import PractitionerFinalSection from '../components/sections/practitionerForm/PractitionerFinalSection';


const PractitionerForm: React.FC = () => {

    const [getSection, setSection] = useState<number>(1);
    const [practitionerInformation, setPraticitionerInformation] = useState<PractitionerLead>();

    const [getAddress, setAddress] = useState<string>("");

    const goToNextSection = () => {
        setSection(getSection + 1);
    };
    const updateWithPersonalInformation = (e: any) => {
        goToNextSection();
    }

    const updatePractitionerInformation = (e: Object) => {
        goToNextSection();
    }

    return (
        <>
        {
            getSection==1?<PersonalInformationSection onComplete={(e:Object)=>updateWithPersonalInformation(e)} key={1} />:""
        }
        {
            getSection==2?<EligibilityInformationSection onComplete={(e:object)=>updatePractitionerInformation(e) }  />:""
        }
        {
            getSection==3?<PractitionerFinalSection/>:""
        }
        </>
    );
};

export default PractitionerForm;