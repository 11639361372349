import React from 'react';
import SubmitBtn from '../components/FormComponents/SubmitBtn';
import FormInput from '../components/FormComponents/FormInput';
import {useParams} from 'react-router-dom';
import DoneBtn from '../components/FormComponents/DoneBtn';
import MessageSection from '../components/sections/common/MessageSection';
import BackBtn from '../components/FormComponents/BackBtn';
import {BaseURL} from '../store/states/initialStates';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../state/store';
import { setDbeConsent } from '../state/dbe/dbeSlice';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ConsentFormView = () => {
    document.title = 'DBE Consent | SmartStart';

    const {id} = useParams();
    const [consented, setConsented] = React.useState<Boolean>();
    const [isSubmitted, setIsSubmitted] = React.useState<Boolean>(false);
    const [submitFailed, setSubmitFailed] = React.useState<Boolean>(false);
    const [submitError, setSubmitError] = React.useState<String>("");
    const [doneBtnVisible, setDoneBtnVisible] = React.useState<Boolean>(true);
    const dbeConsent = useAppSelector(state => state.dbe.dbe);
    const dispatch = useAppDispatch();

    const handleSubmit = async () => {
        await fetch(`${BaseURL}/api/Franchisee/dbeConsent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "personalNumber": id,
                "consentGiven": consented
            })
        }).then((response) => {
            setIsSubmitted(true);
            if (response.ok) {
            } else if (response.status === 404) {
                setConsented(undefined);
                setSubmitFailed(true);
                setSubmitError("The personal number you entered was not found.");
            } else {
                setConsented(undefined);
                setSubmitFailed(true);
                response.json().then((data) => {
                    let error = "";
                    if (data.errors) {
                        for (const key in data.errors) {
                            error += data.errors[key][0] + " ";
                        }
                    } else if (data.detail) {
                         error = data.detail;
                    }
                    setSubmitError(error);
                });
            }
        }).catch((error) => {
            setIsSubmitted(true);
            setSubmitFailed(true);
            setSubmitError(error.message);
        });
    };
    const handleConsent = (consent: boolean) => {
        setConsented(consent);
        dispatch(setDbeConsent({
            "personNumber": id?.toString()!,
            "consentGiven": consent
        }));
    };

    return (
        <div className="form-wrapper">
            {
                isSubmitted ? isSubmitted && submitFailed ? (
                    <>
                        <MessageSection
                            title={"Something Went Wrong!"}
                            body={
                                <div>
                                    <p className='mb-1'>{submitError}</p>
                                    <p>For immediate assistance, please contact SmartStart at <a
                                        className='text-primary text-nowrap' href="tel:+27 11 268 5959">+27 (0)11 268 5959</a></p>
                                </div>
                            }
                            button={<BackBtn onClick={() => {
                                window.location.reload();
                            }}/>}
                        />
                    </>
                ) : (
                    <>
                        <h2 className='text-primary'>Thank you for your selection.</h2>
                        <p className={"fs-6 my-3"}>
                            Your contact and programme details will {consented ? "" : <b>NOT</b>} be shared with the <b>DBE</b>.
                        </p>
                        <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                            {doneBtnVisible ? <DoneBtn onClick={() => {
                                setDoneBtnVisible(false)
                            }}/> : ""}
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className='text-primary'>Consent Form</h2>
                        <p className={"fs-6 my-3"}>
                            Government is launching a mass registration drive to help <b>ECD</b> sites register
                            with <b>DBE</b>.
                            They want to share information and tools to support you but need your contact details.
                            <br/>
                        </p>

                        <label htmlFor="" className='fw-medium'>Phone Number:</label>
                        <FormInput inputType={'number'} placeholder={'Phone number*'} isRequired={true}
                                   getValue={(value) => {
                                   }} defaultValue={id} disabled={true}/>
                        <p>
                            Please answer '<b>Yes</b>' if we can share your programme details with government so they
                            can contact you directly through their Whatsapp registration system.
                            <br/>
                            <br/>
                            Please note that SmartStart will not share your contact details with anyone unless you give
                            us your permission as asked below.
                            <br/>
                            <br/>
                            <b>Don’t miss this opportunity to help get registered!</b>
                        </p>

                        <div className="row my-4">
                            <div
                                className="col-12 flex items-center d-flex flex-row justify-content-start align-items-center mb-3">
                                <input type="radio" id="No" checked={consented === true || undefined}
                                       onChange={() => handleConsent(true)} className="me-2 radio"/>
                                <b className={"me-2"}>YES</b> - I am happy to share my contact details with DBE
                            </div>
                            <div
                                className=" col-12 flex items-center d-flex flex-row justify-content-start align-items-center">
                                <input type="radio" id="No" checked={consented === false || undefined}
                                       onChange={() => handleConsent(false)} className="me-2 radio"/>
                                <b className={"me-2"}>NO</b> - I don’t want SmartStart to share my info with DBE.
                            </div>
                        </div>

                        <div className="container d-flex flex-column align-items-center justify-content-center mt-4">
                            {consented !== undefined ? <SubmitBtn onClick={() => handleSubmit()}/> : ""}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default ConsentFormView;